import loadWasm, { fetchGithubApi, fetchGithubMsgpack, loadGithubApi, loadGithubMsgpack, play, search, setup, } from "chatsounds-web";
import React from "react";
export const WasmContext = React.createContext(null);
export default function useWasm() {
    const wasm = React.useContext(WasmContext);
    if (!wasm) {
        throw new Error("!wasm");
    }
    return wasm;
}
export class Wasm {
    static async load() {
        await loadWasm();
        return new Wasm();
    }
    fetchGithubApi = fetchGithubApi;
    fetchGithubMsgpack = fetchGithubMsgpack;
    loadGithubApi = loadGithubApi;
    loadGithubMsgpack = loadGithubMsgpack;
    play = play;
    search = search;
    setup = setup;
}
export var WasmState;
(function (WasmState) {
    WasmState[WasmState["Loading"] = 0] = "Loading";
    WasmState[WasmState["Loaded"] = 1] = "Loaded";
    WasmState[WasmState["Error"] = 2] = "Error";
})(WasmState || (WasmState = {}));
export function useWasmStateInfo() {
    const [stateInfo, setStateInfo] = React.useState(() => ({
        state: WasmState.Loading,
    }));
    React.useEffect(() => {
        Wasm.load().then((wasm) => {
            setStateInfo({ state: WasmState.Loaded, wasm });
        }, (e) => {
            console.error(e);
            setStateInfo({
                state: WasmState.Error,
                error: "Wasm error!",
            });
        });
    }, []);
    return stateInfo;
}
