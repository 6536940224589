import { Callout, Intent, Spinner, SpinnerSize } from "@blueprintjs/core";
import React from "react";
import WasmLoaded from "/src/components/WasmLoaded";
import { WasmContext, WasmState, useWasmStateInfo } from "/src/hooks/useWasm";
export default function WasmLoading() {
    const wasmStateInfo = useWasmStateInfo();
    switch (wasmStateInfo.state) {
        case WasmState.Loaded: {
            return (React.createElement(WasmContext.Provider, { value: wasmStateInfo.wasm },
                React.createElement(WasmLoaded, null)));
        }
        case WasmState.Loading: {
            return (React.createElement("div", { className: "centered" },
                React.createElement(Spinner, { size: SpinnerSize.LARGE })));
        }
        case WasmState.Error: {
            return React.createElement(Callout, { intent: Intent.DANGER, title: wasmStateInfo.error });
        }
        default: {
            throw new Error("unreachable");
        }
    }
}
