import { Menu } from "@blueprintjs/core";
import debounce from "lodash.debounce";
import React from "react";
import { usePrevious } from "react-use";
import ChatsoundsResult from "/src/components/ChatsoundsResult";
import useWasm from "/src/hooks/useWasm";
const MAX_VISIBLE_RESULTS = 50;
export default function ChatsoundsSearchResults({ input, onSetInput, tabSelection, }) {
    const { search } = useWasm();
    const [results, setResults] = React.useState([]);
    const updateSearch = React.useMemo(() => debounce(async (input) => {
        const results = await search(input);
        setResults(results);
    }, 200), [search]);
    React.useEffect(() => {
        updateSearch(input);
    }, [input, updateSearch]);
    const lastTabSelection = usePrevious(tabSelection);
    React.useEffect(() => {
        if (tabSelection !== null && tabSelection !== lastTabSelection) {
            const result = results[tabSelection];
            if (result) {
                onSetInput(result);
            }
        }
    }, [lastTabSelection, onSetInput, results, tabSelection]);
    const visibleResults = React.useMemo(() => results.slice(tabSelection || 0, (tabSelection || 0) + MAX_VISIBLE_RESULTS), [results, tabSelection]);
    const resultClicked = React.useCallback((result) => {
        onSetInput(result, true);
    }, [onSetInput]);
    return (React.createElement("div", null,
        React.createElement(Menu, null, visibleResults.map((result) => (React.createElement(ChatsoundsResult, { key: result, onClick: resultClicked, result: result }))))));
}
