import { OverlayToaster, Position } from "@blueprintjs/core";
import React from "react";
import WasmLoading from "/src/components/WasmLoading";
import { ToasterContext } from "/src/hooks/useToaster";
export default function App() {
    const toasterRef = React.useRef(null);
    const [toaster, setToaster] = React.useState(null);
    React.useEffect(() => {
        setToaster(toasterRef.current);
    }, []);
    return (React.createElement(React.Fragment, null,
        toaster ? (React.createElement(ToasterContext.Provider, { value: toaster },
            React.createElement(WasmLoading, null))) : null,
        React.createElement(OverlayToaster, { ref: toasterRef, position: Position.BOTTOM_RIGHT })));
}
